<template>
  <div class="login">
    <div class="card overflow-hidden">
      <div class="bg-soft-primary">
        <div class="row">
          <div class="col-7">
            <div class="text-primary p-4">
              <h5 class="text-primary">Log In</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div>
          <router-link tag="a" to="/">
            <div class="avatar-md profile-user-wid mb-4">
              <span class="avatar-title rounded-circle bg-white">
                <img src="@/assets/images/logo.png" alt height="34" />
              </span>
            </div>
          </router-link>
        </div>
        <b-alert :show="!!error" variant="danger" class="mt-3" dismissible>{{
          error
        }}</b-alert>

        <!-- <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >{{notification.message}}</div> -->

        <b-form class="p-2" @submit.prevent="login">
          <b-form-group id="input-group-1" label="Email" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="email"
              type="text"
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Password" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="password"
              autocomplete="`password`"
              type="password"
              placeholder="Enter password"
            ></b-form-input>
          </b-form-group>
          <div class="mt-3">
            <ebp-button
              :loading="loading"
              type="submit"
              variant="primary"
              className="btn-block"
              >Log In</ebp-button
            >
          </div>
        </b-form>
        <div class="mt-3 text-center">
          <p>
            <router-link
              tag="a"
              :to="{ name: 'ForgotPassword' }"
              class="font-weight-medium text-primary"
              >Forgot your password?</router-link
            >
          </p>
        </div>
      </div>
    </div>

    <div class="mt-5 text-center">
      <p>
        Don't have an account?
        <router-link
          tag="a"
          :to="{ name: 'Register' }"
          class="font-weight-medium text-primary"
          >Sign up now</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t("login")
    };
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      error: null,
      loading: false
    };
  },
  methods: {
    async login() {
      this.error = null;
      this.submitted = true;
      this.loading = true;

      try {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password
        });
        const user = this.$store.getters["auth/user"];
        this.$router.push({
          name: user.role === "client" ? "ClientDashboard" : "AdminDashboard"
        });
      } catch (err) {
        this.submitted = false;
        this.error = this.errorRes(err);
      }

      this.loading = false;
    }
  }
};
</script>
